import { iUIschema } from '../interfaces/iUiSchema'

export const findUiSchemaElementByScope = (uiSchema: iUIschema[], scope: string): iUIschema | undefined => {
  for (const element of uiSchema) {
    if (element.scope === scope) {
      return element
    }
    if (element.elements) {
      const found = findUiSchemaElementByScope(element.elements, scope)
      if (found) {
        return found
      }
    }
  }
  return undefined
}
