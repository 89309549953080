import get from 'lodash/get'
import set from 'lodash/set'
import { useFormContext } from 'react-hook-form'
import { pathToScope, scopePartToPath } from '../../utils/scopeModifs'
import { useFormRenderContext } from '../context/RenderFormContext'
import { findUiSchemaElementByScope } from '../utils/findUiSchemaElementByScope'

export const useBtnLoadClear = () => {
  const { uiSchemas } = useFormRenderContext()
  const { setValue, getValues } = useFormContext()

  // smaze obsah jednoho opakovani dle path
  const clearRepeatElement = (repeatElementPath: string) => {
    const parts = repeatElementPath.split('.')
    const lastPart = parts.pop()
    const partIndex = Number(lastPart)
    const remainingPath = parts.join('.')

    const arrayScope = pathToScope(remainingPath)
    const arrayLayoutSchema = findUiSchemaElementByScope(uiSchemas, arrayScope)
    const excludeScope = arrayLayoutSchema?.options?.arrayIdentifierScope

    if (lastPart !== undefined && !isNaN(partIndex)) {
      const vals = getValues(remainingPath)
      if (vals && vals.length) {
        const emptyVal = {}
        if (excludeScope) {
          const excludePath = scopePartToPath(excludeScope)
          const excludeVals = get(vals[partIndex], excludePath)
          set(emptyVal, excludePath, excludeVals)
        }
        vals[partIndex] = emptyVal
        setValue(remainingPath, vals)
      }
    }
  }

  return { clearRepeatElement }
}
